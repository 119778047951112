<template>
  <div class="com-footer">
    <div class="foot-wrapper">
      <div class="menu-wrapper">
        <div class="menu">
          <div class="title">解决方案&产品中心</div>
          <div @click="openPage('solution', index)" v-for="(item, index) in solution" :key="item.nameOrigin"
               class="item">{{item.nameOrigin}}
          </div>
        </div>
        <div class="menu">
          <div class="title">落地案例</div>
          <div @click="openPage('partner', null)" v-for="item in cases" :key="item.name" class="item">{{item.name}}
          </div>
        </div>
        <div class="menu">
          <div class="title">关于我们</div>
          <div @click="openPage('about', 0)" class="item">公司简介</div>
          <div @click="openPage('about', 1)" class="item">经营理念</div>
          <div @click="openPage('about', 2)" class="item">所获荣誉</div>
        </div>
        <div class="menu">
          <div class="title">联系我们</div>
          <div @click="openPage('contact', null)" class="item">联系方式</div>
          <!--          <a style="text-underline: none; text-decoration: none" href="mailto:sales@plc-tek.com">-->
          <a style="text-underline: none; text-decoration: none" target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=1252939801&site=qq&menu=yes">
            <div class="item">在线留言</div>
          </a>
        </div>
      </div>
      <div class="ercode-wrapper">
        <div class="wechat">公众号：SGC物联网</div>
        <img class="ercode" src="../../assets/images/ercode.jpg">
      </div>
    </div>
    <div class="copyright">
      <div>Copyright © {{year}} 普立兹智能系统有限公司</div>
      <div class="filings" @click="handleFilingClick">苏ICP备16066195-2号</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import { SOLUTIONS, CASES } from '../../util/constants'

  export default {
    name: 'PLCFooter',
    data () {
      return {
        year: '',
        solution: SOLUTIONS,
        cases: CASES
      }
    },
    created () {
      this.year = (new Date()).getFullYear()
    },
    methods: {
      openPage (page, param) {
        this.$router.push({
          name: page
        })
        if (param !== null) {
          localStorage.setItem('menu', param)
        }
      },
      handleFilingClick() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .com-footer
    background-color #1e1f23
    width 100%

    .foot-wrapper
      padding 55px 0 25px
      margin 0 auto
      width 1200px
      display flex
      justify-content space-between

      .menu-wrapper
        flex 1
        display flex

        .menu
          width 185px
          text-align left

          .title
            color #fff
            font-size 15px
            margin-bottom 20px

          .item
            color #999899
            font-size 13px
            margin-bottom 10px
            cursor pointer

            &:last-child
              margin-bottom 0

            &:hover
              text-decoration underline

      .ercode-wrapper
        text-align center

        .wechat
          color #fff
          font-size 14px
          margin-bottom 14px

        .ercode
          width 128px
          height 128px

    .copyright
      border-top 1px solid #2b2c2c
      padding 30px 0
      text-align center
      font-size 15px
      color #fff
      .filings
        margin-top: 10px
        font-size: 14px
        cursor: pointer
</style>
