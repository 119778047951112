/**
 * Created by ZengFanhui on 2020/12/13 16:57
 */
export const MENUS = [{
  name: '首页',
  url: '/'
}, {
  name: '解决方案',
  url: '/solution'
}, {
  name: '产品中心',
  url: '/product'
}, {
  name: '关于我们',
  url: '/about'
}, {
  name: '落地案例',
  url: '/partner'
}, {
  name: '联系我们',
  url: '/contact'
}]

export const SOLUTIONS = [{
  name: 'AI人工智能</br>盘猪估重系统',
  nameOrigin: 'AI人工智能盘猪估重系统',
  active: true
}, {
  name: '母猪个体</br>健康看护系统',
  nameOrigin: '母猪个体健康看护系统',
  active: false
}, {
  name: '畜禽舍智能</br>环控物联网系统',
  nameOrigin: '畜禽舍智能环控物联网系统',
  active: false
}, {
  name: '畜禽舍环境</br>监测物联网系统',
  nameOrigin: '畜禽舍环境监测物联网系统',
  active: false
}, {
  name: '智能饲喂系统',
  nameOrigin: '智能饲喂系统',
  active: false
}]

export const CASES = [{
  name: '江苏太仓某猪场',
  picUrl: require('../assets/images/partner/partner-1.jpg')
}, {
  name: '河南辛东某猪场',
  picUrl: require('../assets/images/partner/partner-2.jpg')
}, {
  name: '安徽青阳某猪场',
  picUrl: require('../assets/images/partner/partner-3.jpg')
}, {
  name: '四川邛崃某猪场',
  picUrl: require('../assets/images/partner/partner-4.jpg')
}, {
  name: '新疆某蛋种鸡场',
  picUrl: require('../assets/images/partner/partner-5.jpg')
}, {
  name: '福建某猪场',
  picUrl: require('../assets/images/partner/partner-6.jpg')
}, {
  name: '四川某集团公司母猪场',
  picUrl: require('../assets/images/partner/partner-7.jpg')
}, {
  name: '达州某集团公司扩繁场',
  picUrl: require('../assets/images/partner/partner-8.jpg')
}]

export const HONOR = [{
  picUrl: require('../assets/images/honor/1.jpg'),
  desc: '2017荣获全球物联网峰会（台湾）</br>-智慧服务典范「金龙奖」',
  vertical: false
}, {
  picUrl: require('../assets/images/honor/2.jpg'),
  desc: '2017荣获全球物联网峰会（上海）</br>-杰出产品奖',
  vertical: false
}, {
  picUrl: require('../assets/images/honor/3.jpg'),
  desc: '2018智慧养殖最具创新力企业',
  vertical: true
}, {
  picUrl: require('../assets/images/honor/4.jpg'),
  desc: '2018智慧养殖最佳解决方案',
  vertical: true
}, {
  picUrl: require('../assets/images/honor/5.jpg'),
  desc: '普立兹智能控制器著作权',
  vertical: true
}, {
  picUrl: require('../assets/images/honor/6.jpg'),
  desc: '普立兹SGC农业物联网平台软件著作权',
  vertical: true
}, {
  picUrl: require('../assets/images/honor/7.jpg'),
  desc: '自动化体感温度测量系统实用新型专利',
  vertical: true
}, {
  picUrl: require('../assets/images/honor/8.jpg'),
  desc: '传感器功耗测试设备实用新型专利',
  vertical: true
}]

export const AI_PRODUCT_1 = [{
  key: '产品名称',
  value: 'AIHM100'
}, {
  key: '供电电压',
  value: '85VAC-264VAC'
}, {
  key: '最大功耗',
  value: '0.4W'
}, {
  key: '工作环境温度',
  value: '-5-45摄氏度'
}, {
  key: '温度长期稳定性',
  value: '0.5℃'
}, {
  key: '温度分辨率',
  value: '±0.5℃'
}, {
  key: '活动力侦测距离',
  value: '3cm-100cm'
}, {
  key: '响应时间',
  value: '≤ 30S'
}, {
  key: 'WiFi',
  value: '802.11 b/g/n'
}, {
  key: '尺寸',
  value: '192.4mm*88.4mm*61mm（盒子尺寸）'
}, {
  key: '安装方式',
  value: '利用附件的U型卡管M6*30*95mm及M6螺母固定在圆柱长管(直径三公分以內)上'
}]

export const AI_PRODUCT_2 = [{
  key: '产品名称',
  value: 'AI运算服务器（含智慧引擎算法）'
}, {
  key: '型号',
  value: 'AIEC-1060'
}, {
  key: '配置',
  value: 'B360M GAMING HD<br>NTEL I5-8600 3.1G/9M<br>金士顿 DDRIV/2666 16G<br>TOSHIBA 4TB 128M7200'
}]

export const AI_PRODUCT_3 = [{
  key: '产品名称',
  value: 'AI云盒'
}, {
  key: '型号',
  value: 'AIGW200-4G'
}, {
  key: '配置',
  value: 'SoC – Mediatek MT7621 dual-core, quad-thread MIPS1004K processor @ up to 880MHz<br>System Memory – 256MB DDR3 (optional 512 MB)<br>Storage – 16MB NOR Flash (optional 8/32/64MB), 1x SATA 3.0 port, 1x micro SD card slot<br>Connectivity – 5x Gigabit Ports (4x LAN, 1x WAN)<br>1x 3G/4G module with SIM Card<br>2x “normal” mPCIe for 802.11ac or 802.11n WiFi module<br>Power Supply – 12V@1A<br>Power Consumption – 8 Watt (Max)<br>Certifications – CE & FCC Certified, RoHS Compliant'
}, {
  key: '应用场景',
  value: '规模化猪场'
}, {
  key: '产品尺寸',
  value: '154*110*29mm'
}, {
  key: '质保期',
  value: '1年'
}, {
  key: '说明',
  value: '辅材和施工费另计'
}]

export const PRODUCT_AI_WEIGHT = [{
  picUrl: require('../assets/images/product/AI数据采集镜头.png'),
  name: 'AI数据采集镜头',
  vartical: true
}, {
  picUrl: require('../assets/images/product/AI运算服务器.png'),
  name: 'AI运算服务器（含智慧引擎算法）',
  vartical: true
}, {
  picUrl: require('../assets/images/product/NVR.png'),
  name: 'NVR',
  vartical: true
}]

export const PRODUCT_AI_TEMP = [{
  picUrl: require('../assets/images/product/母猪体温监测传感器.png'),
  name: '母猪体温监测传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/AI云盒.png'),
  name: 'AI云盒',
  vartical: true
}, {
  picUrl: require('../assets/images/product/AI运算服务器.png'),
  name: 'AI运算服务器（含智慧引擎算法）',
  vartical: true
}]

export const PRODUCT_MONITOR = [{
  picUrl: require('../assets/images/product/SC820.png'),
  name: '智能控制器SC820',
  vartical: false
}, {
  picUrl: require('../assets/images/product/SOT.png'),
  name: '无线温度传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOH.png'),
  name: '无线湿度传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOD.png'),
  name: '无线二氧化碳传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOA.png'),
  name: '无线氨气传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOI.png'),
  name: '无线光照传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/智能水表.png'),
  name: '智能水表',
  vartical: false
}, {
  picUrl: require('../assets/images/product/智能电表.png'),
  name: '智能电表',
  vartical: false
}]

export const PRODUCT_ENVIRONMENT = [{
  picUrl: require('../assets/images/product/SC230.png'),
  name: '智能控制器SC230',
  vartical: false
}, {
  picUrl: require('../assets/images/product/SOT.png'),
  name: '无线温度传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOH.png'),
  name: '无线湿度传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOD.png'),
  name: '无线二氧化碳传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOA.png'),
  name: '无线氨气传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/SOI.png'),
  name: '无线光照传感器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/智能水表.png'),
  name: '智能水表',
  vartical: false
}, {
  picUrl: require('../assets/images/product/智能电表.png'),
  name: '智能电表',
  vartical: false
}]

export const PRODUCT_FEED = [{
  picUrl: require('../assets/images/product/单体栏精喂仪.png'),
  name: '单体栏精喂仪',
  vartical: false
}, {
  picUrl: require('../assets/images/product/哺乳母猪饲喂器.png'),
  name: '哺乳母猪饲喂器',
  vartical: true
}, {
  picUrl: require('../assets/images/product/哺乳母猪饲喂器(互动版).png'),
  name: '哺乳母猪饲喂器（互动版）',
  vartical: true
}, {
  picUrl: require('../assets/images/product/保育育肥一体机.png'),
  name: '保育育肥一体机',
  vartical: true
}]
