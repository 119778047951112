<template>
  <header class="com-header">
    <div class="header-wrapper">
      <img class="logo" src="../../assets/images/logo/logo.png">
      <div class="menu-wrapper">
        <router-link v-for="item in menu" :key="item.name" :to="item.url" class="menu-item" active-class="active" exact>{{item.name}}</router-link>
      </div>
    </div>
  </header>
</template>

<script type="text/ecmascript-6">
  import { MENUS } from '../../util/constants'

  export default {
    name: 'PLCHeader',
    data () {
      return {
        menu: MENUS
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .com-header
    height 80px
    position fixed
    top 0
    left 0
    right 0
    width 100%
    background-color #fff
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25)
    z-index 9999

    .header-wrapper
      padding 20px 35px
      display flex
      align-items center
      justify-content space-between

      .logo
        width auto
        height 40px

      .menu-wrapper
        display flex

        .menu-item
          margin-left 35px
          color #323333
          text-decoration none
          font-size 16px
          padding 5px 0

          &.active
            color #87c44a
            border-bottom 3px solid #87c44a
</style>
