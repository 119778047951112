<template>
  <div class="com-index">
    <plc-header id="header"></plc-header>
    <plc-banner @loaded="onBannerImgLoaded" @ :slide="slide" :pic-url="picUrl" :pic-urls="picUrls"></plc-banner>
    <div class="com-content" :style="{minHeight: contentMinHeight + 'px'}">
      <slot></slot>
    </div>
    <plc-footer id="footer"></plc-footer>
    <div class="ercode-fixed-wrapper fixed1">
      <div class="ercode-fixed">
        <div class="text">进<br>入<br>A<br>P<br>P</div>
        <div class="ercode-wrapper">
          <img class="ercode-img img1" src="../../assets/images/ercode-1.png">
        </div>
      </div>
    </div>
    <div class="ercode-fixed-wrapper fixed2">
      <div class="ercode-fixed">
        <div class="text">获<br>取<br>解<br>决<br>方<br>案</div>
        <div class="ercode-wrapper">
          <img class="ercode-img img1" src="../../assets/images/ercode-2.png">
        </div>
      </div>
    </div>
    <div class="ercode-fixed-wrapper fixed3">
      <div class="ercode-fixed">
        <div class="text">全<br>景<br>漫<br>游</div>
        <div class="ercode-wrapper">
          <img class="ercode-img img1" src="../../assets/images/ercode-3.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import PlcHeader from './Header'
  import PlcFooter from './Footer'
  import PlcBanner from './Banner'

  export default {
    name: 'PageIndex',
    components: {
      PlcHeader,
      PlcFooter,
      PlcBanner
    },
    props: {
      slide: {
        type: Boolean,
        default: false
      },
      picUrl: {
        type: String,
        default: ''
      },
      picUrls: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data () {
      return {
        contentMinHeight: 0
      }
    },
    methods: {
      onBannerImgLoaded (bannerHeight) {
        this.$nextTick(() => {
          const windowHeight = window.innerHeight
          let footerHeight = 0
          const footerDom = document.getElementById('footer')
          if (footerDom) {
            footerHeight = footerDom.offsetHeight
          }
          let minHeight = windowHeight - footerHeight - bannerHeight - 1
          this.contentMinHeight = minHeight < 0 ? 0 : minHeight
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .com-content
    position relative
    background-color #f4f4f4
  .ercode-fixed-wrapper
    position fixed
    right 0
    font-size 14px
    line-height 1.1
    cursor pointer
    color #fff
    &.fixed1
      top 30%
    &.fixed2
      top calc(30% + 110px)
    &.fixed3
      top calc(30% + 220px)
    .ercode-fixed
      position relative
      display flex
      align-items center
      justify-content center
      width 30px
      height 100px
      background-color #77b539
      /*z-index 100*/
      &:hover
        .ercode-wrapper
          right 35px
          transition all 1s
      .text
        position relative
        /*z-index 100*/
      .ercode-wrapper
        position absolute
        top 0
        right -152px
        z-index -1
        font-size 0
        box-shadow 0 0 5px 5px rgba(0, 0, 0 ,0.3)
        transition all 1s
        .ercode-img
          position relative
          //z-index -1
          width 147px
          &.img1
            height 154px
          &.img2
            height 154px
          &.img3
            height 173px
</style>
