<template>
  <div class="com-banner">
    <div v-if="slide" class="banner-wrapper">
      <div class="slide-banner-wrapper" ref="slide">
        <div class="slide-banner-content">
          <img @load="onBannerImgLoad" v-for="(item, index) in picUrls" :key="index" class="slide-page banner" :src="item">
        </div>
      </div>
      <div class="dots-wrapper">
        <span
          class="dot"
          v-for="(item, index) in picUrls"
          :key="index"
          :class="{'active': currentPageIndex === index}"></span>
      </div>
    </div>
    <div v-else class="banner-wrapper">
      <img @load="onBannerImgLoad" class="banner" :src="picUrl">
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
  import BScroll from '@better-scroll/core'
  import Slide from '@better-scroll/slide'

  BScroll.use(Slide)
  export default {
    name: 'PLCBanner',
    props: {
      slide: {
        type: Boolean,
        default: false
      },
      picUrl: {
        type: String,
        default: ''
      },
      picUrls: {
        type: Array,
        default: function () {
          return []
        }
      }
    },
    data() {
      return {
        currentPageIndex: 0
      }
    },
    mounted () {
      if (this.slide) {
        this._initScroll()
      }
    },
    beforeDestroy () {
      // if (this.scroll) {
      //   this.scroll.destroy()
      // }
    },
    methods: {
      onBannerImgLoad(e) {
        this.$emit('loaded', e.path[0].offsetHeight)
      },
      _initScroll () {
        this.scroll = new BScroll(this.$refs.slide, {
          scrollX: true,
          scrollY: false,
          slide: {
            loop: true,
            interval: 6000
          },
          useTransition: true,
          momentum: false,
          bounce: false,
          stopPropagation: true,
          probeType: 2
        })
        this.scroll.on('slideWillChange', (page) => {
          this.currentPageIndex = page.pageX
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .com-banner
    .banner-wrapper
      position relative
      width 100%
      font-size 0
      .banner
        width 100%
        height auto

    .slide-banner-wrapper
      min-height 1px
      overflow hidden

    .slide-banner-content
      width 100%
      white-space nowrap
      font-size 0

      .slide-page
        display inline-block
        width 100%

    .dots-wrapper
      position absolute
      bottom 20px
      left 50%
      transform translateX(-50%)

      .dot
        display inline-block
        margin 0 4px
        width 8px
        height 8px
        border-radius 50%
        background #eee

        &.active
          width 20px
          border-radius 5px
</style>
